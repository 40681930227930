import React, { useState } from "react";
import Header from "./Header";
import styles from "./styles.module.scss";
import Footer from "Public/components/Footer";
import {Link} from "react-router-dom";

const SmsAlertPage: React.FC = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
    };

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value);
    };

    const isPhoneNumberValid = (phone: string) => {
        // Basic validation for phone number: checks if it's a 10-digit number
        return /^\d{10}$/.test(phone);
    };

    const isFormValid = isChecked && isPhoneNumberValid(phoneNumber)

    const handleSignUpClick = () => {
        alert("Welcome! You will now begin to receive SMS Messages from Reliefscout!");
    };

    return (
        <div className={styles.container}>
          <Header />
          <div className={styles.content}>
            <h1>Sign up for SMS Alerts!</h1>
            <p>Enter your cell phone number below and start receiving notifications when new resources are available in your area.</p>
              <form>
                  <input type="text" placeholder="Phone Number" className={styles.phoneInput} onChange={handlePhoneNumberChange}/>
                  <p><input type="checkbox" onChange={handleCheckboxChange}></input> By checking this box I agree to receive resource program alerts from Reliefscout. I also represent that I have read and agreed to the <Link to="/tos">Terms & Conditions</Link> and <Link to="/privacy">Privacy</Link>. Message frequency varies. Message and data rates may apply.</p>
                  <button className={styles.signUpButton} disabled={!isFormValid} onClick={handleSignUpClick}>Sign Up</button>
              </form>
          </div>
            <Footer />
        </div>
    );
};
export default SmsAlertPage;
